import React, { ReactElement } from 'react';
import {
  Box,
  Center,
  Flex,
  LinkBox,
  LinkOverlay,
  SimpleGrid,
  useToken,
} from '@chakra-ui/react';
import { ColorScheme } from '../../theme/colors';
import { BUTTON_SX_VARIANTS } from '../Button/misc';
import { CTA } from '../CTA';
import { Heading } from '../Heading';
import { Image } from '../Image';
import { ImageRasterProps } from '../ImageRaster';
import { ImageVectorProps } from '../ImageVector';
import { LayoutGridItem } from '../LayoutGrid';
import { RichText, RichTextData } from '../RichText';
import { SmartLineBreak } from '../SmartLineBreak';

export type HeroProps = {
  heroImage?: {
    gatsbyImageData?: ImageRasterProps['image'];
    svg?: { content: ImageVectorProps['image'] };
  };
  heroHeader?: string;
  heroBody?: RichTextData;
  heroCtaText?: string;
  heroCtaUrl?: string;
  heroColorScheme?: ColorScheme;
  variant?: 'narrow' | 'wide';
};

export const Hero = ({
  heroImage,
  heroHeader,
  heroBody,
  heroCtaText,
  heroCtaUrl,
  heroColorScheme,
  variant = 'wide',
}: HeroProps): ReactElement | null => {
  const [focusOutline] = useToken('shadows', ['outline']);

  return !(heroHeader || heroBody?.raw || heroCtaUrl || heroImage) ? null : (
    <LayoutGridItem
      variant={variant === 'wide' ? 'heroWide' : 'heroNarrow'}
      marginBottom={'2rem'}
    >
      <Box
        {...(heroCtaUrl && {
          as: LinkBox,
          borderRadius: 'm',
          transitionProperty: 'box-shadow',
          transitionDuration: 'slow',
          _focusWithin: { boxShadow: focusOutline },
          _hover: {
            '.cta': BUTTON_SX_VARIANTS.solid,
          },
          sx: {
            '& .heroOverlay': {
              outline: '2px solid transparent',
            },
            '&:focus-within:not(:hover)': {
              '& .heroOverlay::after': {
                outline: '2px solid white',
                outlineOffset: '-1px',
              },
            },
          },
        })}
      >
        <SimpleGrid
          templateColumns={{
            base: '1fr',
            m:
              (heroHeader || heroBody?.raw || heroCtaUrl) && heroImage
                ? '1fr 1fr'
                : '1fr',
          }}
          templateRows={{
            base:
              (heroHeader || heroBody?.raw || heroCtaUrl) && heroImage
                ? 'auto auto'
                : '1fr',
            m: '1fr',
          }}
          gridTemplateAreas={{
            base: '"image"\n"text"',
            m: '"text image"',
          }}
          backgroundColor={
            heroColorScheme ? `${heroColorScheme}.500` : undefined
          }
          borderRadius={'m'}
          borderBottomLeftRadius={'m'}
          borderBottomRightRadius={'m'}
          borderTopLeftRadius={'m'}
          borderTopRightRadius={'m'}
          color={'text.onColor'}
          overflow={'hidden'}
          isolation={'isolate'}
        >
          {!(heroHeader || heroBody?.raw || heroCtaUrl) ? null : (
            <Center gridArea={'text'} justifyContent={'flex-start'}>
              <Box
                paddingX={
                  variant === 'wide'
                    ? {
                        base: '1.5rem',
                        m: '2.2rem',
                        xl: '5.8rem',
                      }
                    : { base: 24, s: 45 }
                }
                paddingY={
                  variant === 'wide'
                    ? {
                        base: '1.2rem',
                        m: '2rem',
                        xl: '5rem',
                      }
                    : { base: 24, s: 45 }
                }
              >
                {!heroHeader ? null : (
                  <Heading
                    level={variant === 'narrow' && !heroImage ? 2 : 1}
                    as={'h2'}
                    color={'text.onColor'}
                    marginTop={0}
                    marginBottom={heroBody?.raw ? '1.2rem' : '1.5rem'}
                    withoutLine={!heroBody}
                  >
                    <Box
                      {...(heroCtaUrl
                        ? {
                            as: LinkOverlay,
                            className: 'heroOverlay',
                            href: heroCtaUrl,
                            outline: '2px solid transparent',
                          }
                        : {})}
                    >
                      <SmartLineBreak>{heroHeader}</SmartLineBreak>
                    </Box>
                  </Heading>
                )}
                {!heroBody?.raw ? null : <RichText data={heroBody} />}
                {!heroCtaUrl ? null : (
                  <Box
                    {...(!heroHeader
                      ? {
                          as: LinkOverlay,
                          className: 'heroOverlay',
                          href: heroCtaUrl,
                        }
                      : {})}
                  >
                    <CTA
                      className={'cta'}
                      borderColor={'text.onColor'}
                      color={'text.onColor'}
                    >
                      {heroCtaText}
                    </CTA>
                  </Box>
                )}
              </Box>
            </Center>
          )}
          {!heroImage ? null : (
            <Flex opacity={0.9} gridArea={'image'}>
              <Image image={heroImage} />
            </Flex>
          )}
        </SimpleGrid>
      </Box>
    </LayoutGridItem>
  );
};
