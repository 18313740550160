import React, { ReactElement } from 'react';
import { graphql, PageProps } from 'gatsby';
import { ComingSoon } from '../components/ComingSoon';
import { Hero } from '../components/Hero';
import { ImageFromUrlProps } from '../components/ImageFromUrl';
import { ImageRasterProps } from '../components/ImageRaster';
import { Main } from '../components/Main';
import { RichText, RichTextData } from '../components/RichText';

export type NewsPostQuery = {
  contentfulPageNewsPost: {
    title: string;
    description?: string;
    image: {
      gatsbyImageData?: ImageRasterProps['image'];
      fixed?: { src: ImageFromUrlProps['image'] };
    };
    content?: RichTextData;
    slug: string;
    createdAt: string;
  };
};

const NewsPost = ({ data }: PageProps<NewsPostQuery>): ReactElement => {
  const { title, image, content } = data.contentfulPageNewsPost;

  return (
    <Main
      pageTitle={title}
      hero={<Hero heroImage={image} variant={'narrow'} />}
      variant={'narrow'}
    >
      {!content ? <ComingSoon /> : <RichText data={content} />}
    </Main>
  );
};

export default NewsPost;

export const query = graphql`
  query ($id: String!) {
    contentfulPageNewsPost(id: { eq: $id }) {
      ... on ContentfulPageNewsPost {
        ...NewsPost
      }
    }
  }
`;
